import React, { useCallback, useEffect } from 'react'
import qs from 'query-string'
import { graphql } from 'gatsby'
// eslint-disable-next-line
import { useLocation } from '@reach/router'
import { useManualQuery } from 'graphql-hooks'
import client from '../graphqlClient'
import { Button } from '../components/ui'
import { PageLayoutEvents } from '../components/layout'
import { Pagination, PaginationPaged } from '../components/content'
import {
  MetaTagsMustHave, createPaginatedPageTitle, createPaginatedMetaProps,
} from '../components/seo'
import {
  LocationList, LocationsSearchResults, LocationsFilter, LOCATIONS_SEARCH_QUERY,
} from '../components/locations'

const Locations = ({ data, pageContext, navigate }) => {
  const { themeOptions, locations } = data.wp
  const [requestSearchLocations, searchState] = useManualQuery(LOCATIONS_SEARCH_QUERY, { client })

  const location = useLocation()
  const searchVariables = qs.parse(location.search)
  const isSearchActive = Object.keys(searchVariables).length > 0

  const handleSubmitSearch = useCallback((newSearchValues) => {
    navigate(`${location.pathname}?${qs.stringify(newSearchValues)}`, {
      replace: true,
    })
  }, [location.pathname])

  const handleResetSearch = useCallback(() => {
    navigate(location.pathname, { replace: true })
  }, [location.pathname])

  useEffect(() => {
    if (isSearchActive) {
      const perPage = 25
      const variables = (qs.parse(location.search))

      requestSearchLocations({
        variables: {
          ...variables,
          first: perPage,
          offset: Math.max(0, +variables.page - 1) * perPage,
        },
      })
    }
  }, [isSearchActive, location.search])

  return (
    <>
      <MetaTagsMustHave
        {...createPaginatedMetaProps(
          pageContext.humanPageNumber,
          themeOptions.page.seo,
        )}
      />
      <PageLayoutEvents
        heading={createPaginatedPageTitle(
          pageContext.humanPageNumber,
          themeOptions.page.title,
        )}
      >
        {
          locations.nodes.length > 0 && (
            <LocationsFilter
              mb={3}
              values={searchVariables}
              onSubmit={handleSubmitSearch}
            />
          )
        }
        {
          isSearchActive
            ? (
              <>
                <Button
                  mb={3}
                  mx="auto"
                  variant="text"
                  onClick={handleResetSearch}
                >
                  Szűrők törlése
                </Button>
                <LocationsSearchResults
                  {...searchState}
                  locations={searchState?.data?.locations?.nodes}
                />
                <PaginationPaged
                  pathPrefix={location.pathname}
                  searchVariables={searchVariables}
                  humanPageNumber={+searchVariables.page || 1}
                  hasNextPage={(
                    searchState?.data?.locations?.pageInfo?.offsetPagination?.hasMore ?? false
                  )}
                />
              </>
            ) : (
              <>
                <LocationList locations={locations.nodes} />
                <Pagination {...pageContext} />
              </>
            )
        }
      </PageLayoutEvents>
    </>
  )
}

export default Locations

export const pageQuery = graphql`
  query LocationsQuery($first: Int!, $after: String) {
    wp {
      themeOptions {
        page: locationsPage {
          title
          seo {
            ...SeoFields
          }
        }
      }

      locations(
        first: $first
        after: $after
        where: {
          status: PUBLISH
          language: DEFAULT
          orderby: { field: TITLE, order: ASC }
        }
      ) {
        nodes {
          ...LocationListFields
          ...LocationListImageFields
        }
      }
    }
  }
`

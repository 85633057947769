import React from 'react'
import styled from '@emotion/styled'
import { Button } from '../ui'
import magnifierIcon from '../../data/images/icons/icon-magnifier-dark.png'

const StyledButton = styled(Button)`
  text-indent: -9999px;
  background-image: url(${magnifierIcon});
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary[100]};
  }
`

const SearchButton = (props) => (
  <StyledButton
    width={1}
    type="submit"
    py={3}
    height={[48, 36]}
    minWidth={[48, 'initial']}
    bg={['primary.50', 'primary.50', 'transparent']}
    {...props}
  >
    Keresés
  </StyledButton>
)

export default SearchButton
